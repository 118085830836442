<template>
  <div class="container-page">
    <p class="title-inner">Маппинг стран</p>
    <div class="mb-16">
      <ButtonStock
        :disabled="importInProgress"
        :title="!importInProgress ? 'Добавить значение' : 'Загрузка...'"
        @click="importExcel()"
        v-if="canImport"
      />
    </div>
    <DefaultFilter class="mb-16" :filterValue="filter" @loadFilter="onLoadFilter" />
    <div v-if="canView">
      <div class="table-container mb-16 js-table-arrow">
        <table class="stockTable">
          <thead>
            <tr>
              <th>Наименование страны в справочнике</th>
              <th>Наименование страны в источнике</th>
              <th class="filter"></th>
            </tr>
            <tr>
              <th class="filter">
                <FilterInput
                  type="select2"
                  :select2-options="countries"
                  :select2-settings="select2Settings"
                  v-model="filter.filterCountryID"
                  @update:modelValue="setCountryReferenceName()"
                  placeholder="(Все)"
                  v-on:valueChangedDebounced="changeFilter"
                />
              </th>
              <th class="filter"></th>
              <th class="filter"></th>
            </tr>
          </thead>
          <tbody v-if="page.items?.length && !isLoading">
            <tr v-for="item in page.items" :key="item.id">
              <td>{{ item.countryReferenceName }}</td>
              <td>{{ item.countrySourceName }}</td>
            </tr>
          </tbody>
          <tbody v-else-if="isLoading">
            <tr>
              <td class="text-center" colspan="3">Загрузка...</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-center" colspan="3">Нет данных.</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="page.pagesTotal > 1">
        <Pagination
          :page-count="page.pagesTotal"
          :current-page="page.pageNumber"
          @change="onPageChange($event, false)"
          @more="onPageChange($event, true)"
        />
      </div>
    </div>
    <ModalComponent v-model="importModal" title="Добавить значение" @close="closeModal">
      <div>
        <div class="form-group filter">
          <label class="control-label">Выберите страну</label>
          <FilterInput
            type="select2"
            :select2-options="countries"
            :select2-settings="select2Settings"
            v-model="selectedCountry"
            placeholder="Не указано"
          />
        </div>

        <div class="form-group">
          <label class="control-label">Альтернативное название</label>
          <input type="text" id="text-input" placeholder="Страна Китай" class="form-control" v-model="countryName" />
        </div>

        <div class="mb-16">
          <ButtonStock class="mr-8" title="Загрузить" @click="doImport()" />
          <ButtonStock btn-type="secondary" title="Закрыть" @click="closeModal()" />
        </div>
      </div>
    </ModalComponent>
  </div>
</template>

<script>
  import DefaultFilter from '@/common/components/DefaultFilter';
  import FilterInput from '@/common/components/FilterInput';
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';
  import security from '@/common/mixins/security';
  import Utils from '@/common/utils';

  import ButtonStock from '@/components/buttons/ButtonStock';
  import ModalComponent from '@/components/modals/ModalComponent';

  import Api from '../api/index';

  export default {
    name: 'countrymapping',
    components: { FilterInput, ModalComponent, ButtonStock, DefaultFilter },
    mixins: [security, filtermanager],
    data() {
      return {
        page: {},
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
          IsExport: true,
        },
        filter: {
          CountryReferenceName: null,
          filterCountryID: null,
        },
        importInProgress: false,
        importModal: false,
        isLoading: false,

        select2Settings: Constants.select2Settings,
        canImport: false,
        canView: false,
        countries: [],
        countryName: '',
        selectedCountry: '',
      };
    },
    created() {
      this.checkPermission(Constants.Permissions.BankRateImport).then((response) => {
        this.canImport = response.data;
      });

      this.checkPermission(Constants.Permissions.AnalyticsImportView).then((response) => {
        this.canView = response.data;
        if (!this.canView) {
          this.notAccess();
        } else {
          this.loadFilter().then((response) => {
            let filter = response;
            if (filter) {
              this.filter = filter;
            }
            this.loadDictionaries();
            this.loadPage();
          });
        }
      });
    },
    methods: {
      loadPage(append) {
        this.isLoading = !append;
        const request = Object.assign(this.filter, this.request);
        Api.search('countrymapping', request)
          .then((response) => {
            if (!append) {
              this.page = {};
              this.$nextTick(() => {
                this.page = response.data;
              });
            } else if (response.data) {
              this.page.pageNumber = response.data.pageNumber;
              this.page.items = this.page.items.concat(response.data.items);
            }
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
          });
      },
      loadDictionaries() {
        Utils.searchNsi('countrylist', {})
          .then((response) => {
            this.countries = response.data;
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
      },
      importExcel() {
        this.importModal = true;
      },
      doImport() {
        if (this.countryName && this.selectedCountry) {
          this.importInProgress = true;

          Api.mappingCreate(true, this.selectedCountry, this.countryName)
            .then(() => {
              Constants.alert.fire('Импорт', 'Импорт успешно выполнен.', 'success');
              this.loadPage();
              this.importInProgress = false;
            })
            .catch((error) => {
              Constants.alert.fire(
                'Импорт',
                error?.response?.data ? error?.response?.data : 'Ошибка при импорте данных.',
                'error',
              );
              this.importInProgress = false;
            });
        } else {
          Constants.alert.fire('Импорт', 'Не все поля заполнены', 'error');
        }

        this.closeModal();
      },
      getCountryName(id) {
        if (id && this.countries.length) {
          return this.countries.find((item) => item.id == id).text;
        }
      },

      setCountryReferenceName() {
        this.filter.CountryReferenceName = this.getCountryName(this.filter.filterCountryID);
      },
      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist);
      },
      changeFilter() {
        this.request.pageNumber = 1;
        this.saveFilter(this.filter);
        this.loadPage(false);
      },
      closeModal() {
        this.importModal = false;
        this.selectedCountry = '';
        this.countryName = '';
      },
      onLoadFilter(item) {
        this.filter = item;
        this.changeFilter();
      },
    },
  };
</script>
